import { D as DATE_FORMAT } from "./audit.others.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("div", { staticClass: "add-audit-groups" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("page-header", { attrs: { "title": "Add Audit Group", "back": _vm.onBack, "has-tag": false } }), _c("div", { staticClass: "p-5" }, [_c("div", { staticClass: "card p-4" }, [_c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-row", { staticClass: "mb-3", attrs: { "gutter": [24, 16], "type": "flex" } }, [_c("a-col", { attrs: { "md": 24, "lg": 12 } }, [_c("a-row", { attrs: { "gutter": 24, "type": "flex" } }, [_c("a-col", { attrs: { "xs": 24 } }, [_c("text-input", { attrs: { "span": 24, "label": "Group Name", "max-length": 50, "form-item": "", "rules": "required" }, on: { "change": function($event) {
      return _vm.storeValue("groupName", $event);
    } } })], 1)], 1)], 1), _c("a-col", { attrs: { "md": 24, "lg": 6 } }, [_c("date-picker", { staticClass: "w-100", attrs: { "format-date": _vm.DATE_FORMAT, "label": "Audit Due Date", "form-item": "", "rules": "required" }, on: { "change": function($event) {
      return _vm.storeValue("nextAuditDate", $event);
    } } })], 1), _c("a-col", { attrs: { "md": 24, "lg": 6 } }, [_c("text-input", { attrs: { "span": 3, "label": "Audit Frequency", "suffix": "month", "type": "number", "placeholder": "0", "form-item": "", "rules": "required|integer" }, on: { "change": function($event) {
      return _vm.storeValue("auditFrequency", $event);
    } } })], 1)], 1), _c("a-row", { staticClass: "mb-3", attrs: { "gutter": [24, 24], "type": "flex" } }, [_c("a-col", { attrs: { "xs": 24 } }, [_c("a-row", { attrs: { "gutter": 24, "type": "flex" } }, [_c("a-col", { attrs: { "lg": 12, "xs": 12 } }, [_c("text-area", { attrs: { "span": 12, "label": "Comment", "rows": "4", "max-length": 500 }, on: { "change": function($event) {
      return _vm.storeValue("comment", $event);
    } } })], 1), _c("a-col", { attrs: { "lg": 12, "xs": 12 } }, [_c("a-row", { attrs: { "gutter": 24, "type": "flex" } }, [_c("a-col", { attrs: { "lg": 12, "xs": 12 } }, [_c("select-filter", { key: "CanSizeId", attrs: { "clear-data-on-destroy": false, "label": "Container Size", "lazy": "", "placeholder": "Select a Container size", "reference": "sterilization.common.can-sizes", "search-by": "Name", "source": "id", "source-label": "name", "source-description": "description", "form-item": "", "delete-filter": "IsInactive" }, on: { "change": function($event) {
      return _vm.storeValue("canSizeId", $event.value);
    } } })], 1), _c("a-col", { attrs: { "lg": 12, "xs": 12 } }, [_c("select-filter", { key: "SteriliserId", attrs: { "clear-data-on-destroy": false, "label": "Steriliser", "lazy": "", "placeholder": "Select a steriliser", "reference": "sterilization.common.sterilisers", "search-by": "Description", "source": "id", "source-label": "description", "form-item": "", "delete-filter": "IsInactive" }, on: { "change": function($event) {
      return _vm.storeValue("steriliserId", $event.value);
    } } })], 1), _c("a-col", { staticClass: "pt-3", attrs: { "lg": 24, "xs": 24 } }, [_c("text-input", { attrs: { "span": 12, "label": "Next Audit Fill Condition", "max-length": 40 }, on: { "change": function($event) {
      return _vm.storeValue("nextAuditFillConditions", $event);
    } } })], 1)], 1)], 1)], 1)], 1)], 1), _c("a-divider"), _c("a-row", { attrs: { "gutter": 0, "justify": "end", "type": "flex" } }, [_c("a-button", { on: { "click": _vm.onBack } }, [_vm._v("Cancel")]), _c("a-button", { staticClass: "ml-2", attrs: { "type": "primary" }, on: { "click": function($event) {
      return handleSubmit(_vm.onSaveClick);
    } } }, [_vm._v("Save")])], 1)];
  } }]) })], 1)])], 1)]);
};
var staticRenderFns$1 = [];
var CreateAuditGroup_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".add-audit-groups .card[data-v-007f56cc]{background:#ffffff;border:1px solid #eaedf0}.add-audit-groups .divider[data-v-007f56cc]{border:1px solid #eaedf0}.add-audit-groups .header[data-v-007f56cc]{background:white;box-shadow:inset 0 -1px #eaedf0}.add-audit-groups .header .header-title[data-v-007f56cc]{font-style:normal;font-weight:500;font-size:20px;line-height:28px}.add-audit-groups .header .arrow-left[data-v-007f56cc]{font-size:18px}\n")();
const __vue2_script$1 = {
  name: "CreateAuditGroup",
  inject: ["apiUrl", "crud"],
  data() {
    return {
      DATE_FORMAT,
      entity: null,
      itemsMenu: [
        {
          key: "dataEntry",
          title: "Data Entry",
          path: ""
        },
        {
          key: "auditGroup",
          title: "Audit Group",
          path: "/sterilization-audits/audit-groups"
        },
        {
          key: "addAuditGroup",
          title: "Add Audit Group",
          path: "/sterilization-audits/audit-groups/create"
        }
      ]
    };
  },
  destroyed() {
    this.crud.clearEntity("createForm");
  },
  methods: {
    onBack() {
      this.$router.push("/sterilization-audits/audit-groups");
    },
    storeValue(resourceKey, value) {
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    async onSaveClick() {
      const response = await this.crud.submitEntity("create");
      if (response && response.data && response.data.id) {
        const { id } = response.data;
        this.$router.push({
          name: "EditAuditGroup",
          params: {
            id
          }
        });
      }
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "007f56cc", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateAuditGroup = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-audit-group" }, [_c("resource", { attrs: { "name": "sterilization.common.can-sizes", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.common.sterilisers", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.audit-groups", "api-url": _vm.apiUrl, "create": _vm.CreateAuditGroup, "redirect-route": "/sterilization-audits/audit-groups" } })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "CreateAuditGroup",
  data() {
    return {
      CreateAuditGroup,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
